.containerMain {
  padding: 15px;
  padding-bottom: 50px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border: 2px solid var(--theme-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}

.heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--theme-color);
  margin-bottom: 1rem;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.label {
  font-size: 1rem;
  font-weight: 600;
}

.formInput {
  width: 100% !important;
}

.fileInputContainer {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 120px;
}

.fileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}

.fileInputOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px dashed var(--theme-color);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.fileInputOverlay > span {
  font-weight: 600;
  font-style: italic;
}

.fileInput-text {
  color: var(--theme-color);
  font-weight: bold;
  text-align: center;
}

.browse-files-text {
  color: var(--theme-color);
  font-weight: bold;
  margin-top: 5px;
  cursor: pointer;
}

.requiredStar {
  color: red;
  font-weight: bold;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column !important;
    width: 100%;
  }
}

/* CSS Styles */
.formGroup {
  margin-bottom: 20px;
}

.label {
  font-weight: bold;
  display: block;
  margin-bottom: 8px;
}

.requiredStar {
  color: red;
}

.radioGroup {
  display: flex;
  flex-direction: column;
}

.radioGroup input[type="radio"] {
  margin-right: 8px;
}

.radioGroup label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.radioGroup label input {
  margin-right: 8px;
}

.formGroup {
  margin-bottom: 1rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
}

.requiredStar {
  color: red;
}

.formInput {
  width: 100%;
}

.MuiFormHelperText-root {
  min-height: 1.5em; /* Reserve space for the error message */
  margin-top: 0.25rem; /* Adjust margin as needed */
}

.Mui-error {
  color: red; /* Adjust color as needed */
  font-size: 0.875em; /* Smaller font size for error messages */
}
