@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@300;400;500;600;700&display=swap");

:root {
  /* --theme-color: #a52a2a;
  --color-hover-dark: #7c3030;
  --map-header: #8b0000;
  --table-header: #8b0000; */

  --theme-color: #069edb;
  --theme-color-dark: #105bab;
  --color-hover-dark: #105bab;
  --theme-color-2: #0591ca;
  --theme-color-white: #fff;

  --map-header: #069edb;
  --table-header: #069edb;

  --color-very-low: #105babd5;
  --color-very-low-border: #105bab;

  --color-low: #40c8f4;
  --color-low-border: #105bab;

  --color-medium: #ffe06a;
  --color-medium-border: #fdd225;

  --color-high: #60bb46;
  --color-high-border: #228a05;
}

* {
  font-family: "DM Sans", sans-serif !important;
  font-size: medium;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
