.resources-container {
  left: 0;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5rem;
}

.content-resources {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  width: 100% !important;
  position: relative;
  margin-bottom: 30px;
  align-items: flex-start;
  justify-content: space-between;
  clear: both;
}

.content-resources::before,
.content-resources::after {
  content: " ";
  display: table;
  clear: both;
  top: 60px;
}

.content-resources::after {
  margin-bottom: 5rem;
}

/* SideBar Styling */
.left-part {
  z-index: 0;
  flex: 0.2;
  top: 60px;
  height: 568px;
  display: block;
  float: left !important;
  position: -webkit-sticky;
  position: sticky;
}

.module-title {
  text-align: center;
  color: rgba(255, 255, 255, 1);
  background-color: var(--theme-color);
  font-size: 1.2em;
  font-weight: 600;
  margin: 0.8em 0 0.8em;
}

.list-group {
  border-radius: 0 !important;
}

.module-text {
  border: 1px solid rgba(0, 0, 0, 1);
  font-size: 0.8em;
  line-height: 1.3em;
  background-color: var(--theme-color);
  background-image: none;
  padding-top: 3px;
}

#resourceMenu {
  height: 300px;
  color: #404040;
  overflow-y: auto;
  background-color: #f6f6f6;
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0.003em;
}

#resourceMenu a {
  color: #000000;
}

.nav-link__resources {
  border-bottom: solid 1px #928d8d;
  display: block;
  padding: 0.5rem 1rem;
}

.bullet {
  color: blue;
  font-size: 1.2em;
}

.nav-link__resources a {
  outline: rgb(255, 98, 171) solid 1px !important;
  transition: all 0.3s ease-in-out;
}
/* StyleBar Styling End */

/* Resource Content Styling */
.right-part {
  width: 100%;
  top: 210px;
  float: right;
  display: block;
}

.right-module-title {
  font-weight: 600;
  color: rgb(0, 95, 248);
  margin-bottom: 20px;
}

.tab-container {
  border: 1px solid black;
  padding: 10px;
  max-width: max-content;
  width: 100%;
}

.tab-heading {
  color: rgb(0, 95, 248);
}

.card-container-resources {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2px !important;
}

.tab-container-heading {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* word-break: break-all; */
  color: var(--theme-color);
  font-weight: 600;
  margin-bottom: 10px;
}

.tab-container-card {
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 160px;
}

.tab-card-top {
  background-color: #f6f6f6;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  font-size: 0.8em;
  word-wrap: break-word;
  width: 100%;
}

.tab-image-container {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  width: 100%;
  height: max-content;
}

.tab-image {
  padding: 5px;
  width: 100%;
  font-size: 40px;
  color: var(--theme-color) !important;
  border: 0;
  box-sizing: border-box;
  max-width: 100%;
  height: 90px;
}

#tab-icon {
  padding: 5px;
  width: max-content !important;
  height: 80px !important;
  border: 0;
  vertical-align: middle;
  box-sizing: border-box;
  max-width: 100%;
}
/* Resource Styling Ends */
@media (max-width: 990px) {
  .right-container {
    flex: 0.6;
    width: 100%;
  }
  .left-part {
    flex: 0.3;
  }
}

@media (max-width: 768px) {
  .right-container {
    flex: 0.5;
    width: 100%;
  }
  .left-part {
    flex: 0.4;
  }
}

@media (max-width: 531px) {
  .right-container {
    flex: 1;
    width: 100%;
  }
  .left-part {
    flex: 1;
    width: 100%;
    position: unset;
  }

  .content-resources {
    flex-direction: column;
    margin-bottom: 0px;
  }
}

@media (max-width: 365px) {
  .tab-container-card {
    width: 49%;
  }
}

@media (max-width: 315px) {
  .tab-container-card {
    width: 100%;
  }
}

.right-container {
  flex: 0.8;
  width: 100%;
}
