.links-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 0 0.5rem 0 0;
  text-align: right;
}

.logo-overlay {
  position: absolute;
  left: 0;
  top: 50%;
  width: max-content;
  height: 50px;
}

.checklist {
  text-decoration: underline;
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
}

.training-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.3rem;
}

.training-link a {
  text-decoration: underline;
  font-size: 1.2rem;
  color: black;
  font-weight: 600;
}

.training-link a:hover {
  color: var(--theme-color);
}

.social-logos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0 10px 10px 0;
  position: absolute;
  left: 0;
  top: 80%;
  width: max-content;
}

.social-logos img {
  width: max-content;
  height: 30px;
}

.modal-header {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.modal-title.h4 {
  font-size: 1.2rem !important;
  height: max-content !important;
}

.modal-link {
  color: var(--theme-color) !important;
}

@media (max-width: 500px) {
  .links-container {
    display: none;
  }
}
