.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
}

.leftContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  color: var(--theme-color);
  gap: 0.6rem;
  text-wrap: wrap;
  word-break: break-word;
}

.forms {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 1rem 0;
}

.subHeading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 100%;
  background-color: var(--theme-color);
  color: white;
  font-weight: bold;
  text-transform: capitalize !important;
  text-align: center;
  font-size: 20px;
}

.formCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  background: white;
  max-width: max-content; /* Adjust the max-width to your preference */
}

.registrationForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.registrationText {
  font-weight: 600;
  color: var(--theme-color) !important;
}

.label {
  font-size: 0.9rem;
  font-weight: 500;
}

.subGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.button {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.disabledButton {
  background-color: darkgray !important;
  color: black !important;
}

.registrationFooter {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  color: #a52a2a !important;
  text-transform: capitalize !important;
}

.errorMsg {
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 0;
}

.image {
  align-self: center;
}

@media (max-width: 768px) {
  .formCard {
    margin: 30px 0 120px 0;
  }
  .subGroup {
    flex-wrap: wrap;
  }
}

@media (max-width: 412px) {
  .formCard {
    width: 350px;
    height: 100%;
  }
}

@media (max-width: 388px) {
  .container {
    margin-bottom: 50px;
  }
}

@media (max-width: 360px) {
  .formCard {
    width: 300px;
    height: 100%;
  }
}
