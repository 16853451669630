.editForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.editText {
  font-weight: 600;
  color: var(--theme-color) !important;
}

.label {
  font-size: 0.9rem;
  font-weight: 500;
}

.subGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.button {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.disabledButton {
  background-color: darkgray !important;
  color: black !important;
}

.errorMsg {
  margin-bottom: 0;
  color: #a52a2a !important;
}

.formContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.formRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}

.otpInput {
  width: 50px !important;
  height: 50px !important;
  text-align: center;
}

.otpForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgreen;
  width: 100%;
}

.successText {
  text-align: center;
  margin: 0;
  padding: 10px;
  font-weight: 500;
  color: black;
}

@media (max-width: 768px) {
  .subGroup {
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .otpInput {
    width: 40px !important;
    height: 40px !important;
  }
}
