.home-caption-container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between !important;
  gap: 20px;
  margin-top: 220px;
}

.image-caption-fp {
  transform: translate(-50%, -50%);
  background: black;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  text-align: left;
  width: max-content;
}

.top-caption-fp {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.heading-caption-fp {
  font-weight: 700;
  font-size: 1.2rem;
}

.separator-caption-fp {
  background-color: white !important;
  width: 30px;
  height: 1px;
}

.subheading-caption-fp {
  font-weight: 600;
  font-size: 0.9rem;
}

.image-caption-fp > hr {
  height: 2px;
  background-color: white;
}

.module-progressbar {
  border-radius: 1px;
}

.progress-bar-wrapper {
  position: relative;
}

.tb-progress-bar-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.tb-progress-bar {
  margin-bottom: 5px;
}

.tb-progress-bar-label {
  font-style: normal;
  margin-bottom: 5px;
  text-align: left;
  font-size: 0.8rem;
}

.tb-progress-bar-bg {
  display: block;
  height: 20px;
  border-radius: 1px;
  position: relative;
  overflow: hidden;
}

.progress-bar-wrapper {
  background-color: #ffffff !important;
}

.tb-progress-bar-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: var(--theme-color);
  width: var(--progress-width, 0%);
  transition: width 0.5s ease-in-out;
}

.tb-progress-bar-bg {
  background-color: green !; /* Change the background color for the completed progress bar */
}


@media (max-width: 994px) {
  .home-caption-container {
    margin-top: 235px;
  }
}

@media (max-width: 768px) {
  .home-caption-container {
    margin-left: 50%;
  }
}

@media (max-width: 420px) {
  .image-caption-fp {
    width: max-content;
    height: auto;
  }

  .heading-caption-fp {
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
  }

  .subheading-caption-fp {
    font-weight: 600;
    font-size: 0.8rem;
    /* word-break: normal; */
    text-align: center;
  }
}

@media (max-width: 330px) {
  .image-caption-fp {
    width: max-content;
    height: auto;
    text-align: center;
  }
}

.image-caption-fp-other {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 200px;
}

.top-caption-fp-other {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.heading-caption-fp-other {
  font-weight: 700;
  font-size: 1.4rem;
}

.subheading-caption-fp-other {
  font-weight: 500;
  font-size: 1.2rem;
}
