.contentContainer {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem;
  width: 100% !important;
}

.heroSection {
  display: flex;
  justify-content: flex-end !important;
  height: 100vh;
  padding: 1em;
  box-sizing: border-box;
  color: white;
  background: url("assets/homepage.png") center center no-repeat;
  background-size: cover;
}

.heroContent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  width: 50%;
  padding-top: 50px;
}

.heroContent h3 {
  text-align: center;
  color: white;
  border: 2px solid var(--theme-color);
  padding: 1rem;
  margin: 0 0.5rem 0 0;
  border-radius: 5px 5px 0 0;
  width: 19rem;
}

.heroContent h5 {
  text-align: center;
  color: white;
  margin: 0 0.5rem 0 0;
  border-radius: 5px 5px 0 0;
  width: 20rem;
}

.heroContent h1 {
  text-align: center;
  color: white;
  background-color: var(--theme-color);
  padding: 1rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0 0 5px 5px;
  width: 19rem;
}

.heroContent p {
  text-align: right;
  color: black;
  padding: 1rem 0;
  margin: 0 3rem 0 0;
}

.aboutSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 4rem;
}

.aboutSectionLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 98%;
  border-radius: 5px;
  padding: 1rem;
  margin: 1rem 0;
  text-align: left;
  border: 1px solid var(--theme-color);
}

.aboutSectionRight {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  text-align: left;
}

.aboutSectionHeader {
  color: var(--theme-color);
  text-align: left;
}

.image {
  width: 80%;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.5rem;
}

.card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.25rem;
  padding: 1rem;
  width: 16%;
  height: 180px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  text-decoration: none;
  color: black;
}

.cards .card:hover {
  border: 2px solid var(--theme-color);
}

.icon {
  width: 45px;
  height: 60px;
}

.cardTitle {
  font-weight: 600;
}

.cardBody {
  text-align: left !important;
  font-size: 0.8rem;
}

.keyFeatures {
  margin: 0;
}

.label {
  font-weight: 600;
}

@media (max-width: 768px) {
  .aboutSection {
    flex-wrap: wrap;
  }

  .aboutSectionRight {
    justify-content: center;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem;
    text-align: left;
  }

  .cards {
    text-align: left;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .card {
    width: 30%;
  }

  .image {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .heroSection {
    height: 60vh;
  }

  .heroContent {
    text-align: right;
    width: 80%;
  }

  .heroContent h3,
  .heroContent h1,
  .heroContent p {
    text-align: center;
    width: 100%;
    margin: 0;
  }

  .contentContainer {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    height: 100%;
    gap: 1rem;
    margin-bottom: 10rem;
  }

  .card {
    width: 48%;
    height: max-content;
    gap: 1rem;
  }
}

@media (max-width: 400px) {
  .card {
    width: 100%;
  }
}
