.formContainer {
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
  border-radius: 7.5px;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 20px;
  width: 100%;
}
.formLabel {
  font-weight: 600;
}

.formGroup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.formGroupContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row !important;
  gap: 1rem;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 100%;
}

.statesGroup {
  width: 30%;
}

.datesGroup {
  width: 50%;
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
}

.formInput {
  width: 100%;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.submitBtn {
  border: none;
  padding: 0.5rem 1rem;
  color: #fff;
  background: var(--theme-color);
  width: max-content;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.submitBtn:hover {
  background-color: darkred;
}

.radioHeading {
  font-weight: 600;
}

.radioContainer {
  display: flex;
  width: max-content;
  flex-direction: row;
  gap: 10px;
  padding: 1rem;
}

.radioButton {
  display: flex;
  flex-direction: row;
}

.MuiFormControl-root {
  width: 50% !important;
}

@media (max-width: 768px) {
  .form {
    flex-wrap: wrap;
  }
  .formGroupContainer {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center !important;
    width: 100%;
  }
  .centerRow {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .formGroup {
    width: 100% !important;
  }

  .statesGroup {
    width: 100%;
  }
  .datesGroup {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    gap: 10px;
  }
  .MuiFormControl-root {
    width: 100% !important;
  }
}

@media (max-width: 420px) {
  .datesGroup {
    flex-wrap: wrap;
    width: 100%;
  }
  .formGroup {
    width: 100%;
  }

  .centerRow {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .MuiFormControl-root {
    width: 100% !important;
  }
}
