.amb-footer {
  width: 100%;
  padding: 10px 5%;
  background: #1c1d1d;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: max-content;
  gap: 10px;
  position: fixed;
  bottom: 0;
}

.amb-logo-unicef {
  width: 100%;
  height: 25px;
}

.amb-logo-nutrition {
  width: 100%;
  height: 25px;
}

.amb-logo-ieg {
  width: 100%;
  height: 25px;
}
