.layout {
  height: 85px !important;
  border-color: var(--theme-color) !important;

  margin: auto;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.guZdik {
  height: 85px !important;
  border-color: var(--theme-color) !important;

  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.sc-aXZVg {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
}

@media (max-width: 966px) {
  .layout {
    height: 140px !important;
  }

  .guZdik {
    height: 140 !important;
  }
}
