.ticker-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
  background: var(--theme-color-2);
  border: 1px solid #ccc;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
}

.ticker {
  display: inline-block;
  will-change: transform;
  animation: scroll 20s linear infinite;
  white-space: nowrap;
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
