#footer-container {
  background: #1c1d1d;
}

.footer-header-text {
  color: white;
}

.bottom-border {
  width: 50px;
  height: 3px;
  margin-top: 10px;
  background-color: var(--theme-color);
}

.ncear-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  padding-top: 20px;
  gap: 30px !important;
}

.footer-ncer-logo {
  width: auto;
  height: 70px;
}

.logos-all-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 20px !important;
}

.social-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px !important;
}

.social-icon-ncear {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-weight: 600;
}

.footer-sub-text {
  color: white;
}

.address-container {
  width: 100%;
}

.address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px !important;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px !important;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px !important;
}

.copyright {
  color: white;
  font-weight: 400;
  text-align: center;
  padding: 10px;
}
