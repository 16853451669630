.resourceTableContainer {
  border-radius: 7.5px;
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100% !important;
  height: 500px;
  overflow: auto !important;
  padding: 10px;
}

.resourceTableRow > td {
  font-size: 0.85rem !important;
  width: 100% !important;
}

.searchRow {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: center;

  width: 100% !important;
  gap: 20px;
}

.table {
  overflow: hidden;
}
.table,
.table tr,
.table td,
.table th {
  padding: 10px;
  text-align: center;
  border-collapse: separate;
}

.table thead,
.table-bordered thead,
.table-responsive thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1; /* Optional: Add a z-index to ensure the header is above the table content */
}

.table td {
  color: "black";
  text-align: left;
  font-weight: 500;
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: transparent;
}
.table {
  padding: 0;
  overflow: hidden;

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}

.table th {
  font-size: 0.9rem;
  font-weight: 600;
  background-color: var(--table-header);
  color: var(--theme-color);
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #ececec50;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}
