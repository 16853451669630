.contact-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 1rem;
  margin-bottom: 6rem;
  gap: 1rem;
}

.colorApply {
  color: var(--theme-color);
  font-weight: 600;
}

.colorApplyForStar {
  color: var(--theme-color-dark);
  font-size: 18px;
  font-weight: bold;
}

.classForText {
  font-weight: 700;
  font-size: 15px;
}

.adjustTextSize {
  font-size: 14px;
}

.custom-radio {
  width: 16px;
  height: 16px;
  border: 2px solid gray;
  cursor: pointer;
}

.custom-checkbox {
  width: 16px;
  height: 16px;
  border: 2px solid gray;
  cursor: pointer;
}

.form-check {
  margin-left: 19px;
}

.radioError {
  margin-left: 19px;
}

.adjustSpace {
  margin-left: 8px;
}

.errorBack {
  background-color: #f2dede;
  padding: 12px;
  border-radius: 5px;
  flex-direction: column;
  flex-grow: 2;
}

.noerror {
  background-color: white;
}

.errormsg{
  font-weight: 700;
  font-size: 14px;
  color: #a94442;
}

@media (max-width: 600px) {
  .classForText {
    font-size: 13px;
  }
  .errormsg{
    font-size: 13px;
  }
  .adjustTextSize {
    font-size: 13px;
  }
  .errorBack {
    padding: 8px;
  }
}