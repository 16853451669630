header {
  flex-shrink: 0;
}

footer {
  flex-shrink: 0;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
  border-radius: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 5px;
}

.heading-page {
  background-color: var(--theme-color);
  color: white;
  padding: 5px;
  font-weight: bold;
  text-transform: capitalize !important;
  text-align: center;
  margin-bottom: 0.8rem;
  margin-top: 10px;
  font-size: 1.2rem;
}

.connect {
  margin-top: 70px;
  padding: 20px;
  justify-content: center;
  display: flex;
}

.text-theme {
  color: var(--theme-color-dark);
}

.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 80px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: var(--theme-color);
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: var(--theme-color);
  border: 2px solid var(--theme-color);
}

.under-construction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.center-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.under-construction {
  max-width: 100%;
  max-height: 80vh;
  width: auto;
  height: auto;
}
#delete-icon {
  color: #dc3545;
  font-size: 20px;
  width: 100%;
  border: 0;
  height: auto;
}

.visiter-count {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  background-color: var(--theme-color);
  color: white;
  font-weight: 500;
  padding: 10px;
  border-radius: 0 0 0 10px;
  font-size: 0.9rem;
}

.table-group-divider {
  border-top: 2px solid var(--theme-color-white) !important;
}

p {
  margin-bottom: 0 !important;
}

.css-128fb87-MuiDataGrid-toolbarContainer {
  justify-content: flex-end !important;
}

.css-ptkaw2-MuiDataGrid-root {
  background-color: white !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

@media (max-width: 600px) {
  .connect {
    flex-direction: column;
  }
}
@media (max-width: 550px) {
  .icon-position {
    bottom: 110px;
    right: 10px;
  }
}

@media (max-width: 372px) {
  .icon-position {
    bottom: 140px;
  }
}
