/* CSS to hide amb__header-sticky when scrolling */
.amb__header-sticky {
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s;
  position: -webkit-sticky;
  position: sticky;
  background-color: #ffffff;
  padding: 10px;
  top: 0;
  width: 100%;
  margin: 0;
  z-index: 10;
}

.nav-item.dropend {
  padding-bottom: 8px;
}

.amb__header-sticky.hide-on-scroll {
  transform: translateY(-100%);
}

.amb__navbar {
  background-color: white;
}

.navbar-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around !important;
  gap: 3rem !important;
  width: 100%;
}

.navbar-main {
  background-color: white !important;
}

.navbar-toggler {
  text-decoration: none;
  width: max-content;
}

.navbar-collapse {
  align-items: center;
  justify-content: center;
}

.middle-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 40px;
}

.navbar-nav .dropdown-menu .dropdown-item {
  padding-bottom: 10px !important;
}

.navbar-collapse {
  width: 100%;
}

/* CSS for amb__navbar after scrolling */
.amb__navbar.scrolled {
  position: fixed;
  width: 100%;
  height: 60px;
  margin-top: 0px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  justify-content: space-between;
}

.amb__navbar.scrolled:hover {
  color: var(--color-hover-dark) !important;
}

.amb__sh-emblem {
  height: 80px;
}

.amb__sh-logo {
  height: 80px;
}

.amb__sh-poshan {
  height: 115px;
}

.amb__sh-nhm {
  height: 70px;
}

.basic-navbar-nav a {
  color: black !important;
  padding: 0 18px 0 18px !important;
  background-color: transparent;
  font-weight: 600;
  height: max-content !important;
}

.basic-navbar-nav a:hover {
  color: var(--color-hover-dark) !important;
  background-color: transparent;
}

.basic-navbar-nav a:active {
  color: var(--color-hover-dark) !important;
  outline: none;
  background-color: transparent;
}

.basic-navbar-nav.scrolled a {
  color: black !important;
  background-color: transparent;
}

.basic-navbar-nav.scrolled a:hover {
  color: var(--color-hover-dark) !important ;
  background-color: transparent;
}

.navbar-main {
  color: #ffffff !important;
}

.reports-dropdown {
  align-items: start !important;
  justify-content: start;
  text-align: left;
}

@media (min-width: 300px) and (max-width: 480px) {
  .amb__sh-emblem {
    height: 75px;
  }

  .amb__sh-logo {
    height: 75px;
  }

  .amb__sh-poshan {
    height: 75px;
  }

  .amb__sh-nhm {
    height: 75px;
  }

  .middle-logo {
    margin: 0 5px 0 5px;
    gap: 10px;
  }
}

@media (max-width: 300px) {
  .amb__sh-emblem {
    height: 60px;
  }

  .amb__sh-logo {
    height: 60px;
  }

  .amb__sh-poshan {
    height: 60px;
  }

  .amb__sh-nhm {
    height: 60px;
  }

  .middle-logo {
    margin: 0 5px 0 5px;
    gap: 5px;
  }
}

.justify-content-center > * {
  margin-inline: 0.75rem;
}

.training-page-logo{
  width: 200px;
}