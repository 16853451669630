.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
}

.formCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  gap: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  background: white;
  max-width: max-content; /* Adjust the max-width to your preference */
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.loginText {
  font-weight: 600;
  color: var(--theme-color) !important;
}

.label {
  font-size: 0.9rem;
  font-weight: 500;
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
}

.otpInput {
  width: 70px !important;
  height: 70px !important;
  text-align: center;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}

.button {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.disabledButton {
  background-color: darkgray !important;
  color: black !important;
}

.loginFooter {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.forgotPassword {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  color: var(--theme-color) !important;
  text-transform: capitalize !important;
}

.captcha {
  width: 100% !important;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

/* @media (max-width: 500px) {
  .formCard {
    width: max-content;
    height: 100%;
  }
} */

@media (max-width: 500px) {
  .formCard {
    width: 400px;
    height: 100%;
  }
  .otpInput {
    width: 40px !important;
    height: 40px !important;
  }
}

@media (max-width: 400px) {
  .formCard {
    width: 300px;
    height: 100%;
  }
}
