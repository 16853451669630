.denominators-container {
  left: 0;
  width: 100%;
  margin-top: 1em;
}

.content-denominators {
  display: flex;
  flex-wrap: wrap !important;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.row-denominators {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  gap: 15px;
  width: 100%;
}

.row-text {
  font-weight: 600;
}

.form-container {
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
  border-radius: 7.5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1rem;
  gap: 20px;
  width: 100%;
}

.form-group-container {
  display: flex;
  flex-direction: row !important;
  gap: 1rem;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  width: 100%;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: max-content;
}

.states-group {
  width: 30% !important;
}

.dates-group {
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
}

.form-label {
  font-weight: 600;
}

.form-input {
  width: 100%;
  background: transparent !important;
}

.form-input {
  width: 100%;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.submit-btn {
  border: none;
  font-size: 1rem !important;
  padding: 0.25rem 0.75rem;
  color: #fff;
  background: var(--theme-color);
  width: max-content;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.radio-heading {
  font-weight: 600;
}

.radio-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}

.radio-button {
  display: flex;
  flex-direction: row;
}

.center-row {
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.first-column {
  color: var(--theme-color) !important;
  font-weight: 600;
}

@media (max-width: 768px) {
  .form {
    flex-wrap: wrap;
  }
  .form-group-container {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start;
    justify-content: center !important;
    width: 100%;
  }
  .form-input {
    width: 100%;
  }

  .form-group {
    width: 100% !important;
  }

  .states-group {
    width: 100% !important;
  }
  .dates-group {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    gap: 10px;
  }
}

@media (max-width: 545px) {
  .denominators-container {
    margin-bottom: 140px !important;
  }
}

@media (max-width: 420px) {
  .dates-group {
    flex-wrap: wrap;
    width: 100%;
  }
  .form-group {
    width: 100%;
  }

  .center-row {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }
}

@media (max-width: 448px) {
  .radio-container {
    flex-wrap: wrap;
    flex-direction: column !important;
    gap: 10px;
  }
}
