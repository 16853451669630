.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
}

.formCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  background: white;
  max-width: max-content; /* Adjust the max-width to your preference */
}

.formGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
}

.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgreen;
  width: 100%;
}

.successText {
  text-align: center;
  margin: 0;
  padding: 10px;
  font-weight: 500;
  color: black;
}

@media (max-width: 768px) {
  .formCard {
    margin: 30px 0 120px 0;
  }
}

@media (max-width: 412px) {
  .formCard {
    width: 350px;
  }
}

@media (max-width: 360px) {
  .formCard {
    width: 300px;
    margin-top: 20px;
    margin-bottom: 230px;
  }
}
