.top-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10px;
  border-bottom: 0.05px solid #ccc;
}

.top-logo {
  width: 100%;
  height: 70px;
}

.navbar-ncear {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#fixed-header {
  background-color: #ffffff;
  top: 0;
  width: 100%;
  margin: 0;
  z-index: 10;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-nav {
  display: flex;

  align-items: center;
  justify-content: center;
  gap: 25px !important;
}

.navbar-toggler {
  text-decoration: none;
  width: max-content;
  margin: 0 10px;
}

.navbar-collapse .collapse .show {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
}

.navbar-link {
  font-weight: 600;
  color: black !important;
  background-color: transparent;
}

.navbar-link:hover {
  color: var(--color-hover-dark) !important;
  outline: none;
}

.navbar-link:active {
  color: var(--color-hover-dark) !important;
  outline: none;
}

@media (max-width: 768px) {
  .navbar-ncear {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navbar-nav {
    gap: 10px !important;
  }
}
