/* Resource Content Styling */

.folder-tab-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

.category-card-container {
  padding: 10px 30px 10px 10px;
  margin-bottom: 20px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
}

.tab-content-v2 {
  width: 100%;
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.tab-container-v2 {
  padding: 10px;
  width: 100%;
  height: max-content;
}

.tab-container-heading-v2 {
  color: rgb(0, 95, 248);
  font-weight: 600;
  margin-bottom: 5px;
  word-wrap: break-word;
}

.tab-heading-v2 {
  color: rgba(239, 68, 93, 1);
  font-weight: 600;
  margin-bottom: 10px;
}

.tab-container-card {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  width: 19.65% !important;
}

.tab-card-top-v2 {
  background-color: #f6f6f6;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  font-size: 0.8em;
  word-wrap: break-word;
  width: 100%;
}

#tab-icon {
  padding: 5px;
  width: 100%;
  font-size: 40px;
  color: var(--theme-color);
  border: 0;
  height: auto;
}

.card-container-resources-v2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2px !important;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.resources-file-icon {
  width: 100%;
  height: 100px;
  padding: 5px;
  font-size: 56px !important;
  color: var(--theme-color);
}

/* Resource Styling Ends */

/* @media ((min-width: 1200px) and  (max-width: 2240px)) {
  .right-container {
    flex: 0.7;
    width: 100%;
  }
  .left-part {
    flex: 0.2;
  }
  .tab-container-card {
    width: 33%;
  }
} */

@media (max-width: 992px) {
  .right-container {
    flex: 0.6;
    width: 100%;
  }
  .left-part {
    flex: 0.3;
  }
  .tab-container-card {
    width: 33% !important;
  }
}

@media (max-width: 768px) {
  .right-container {
    flex: 1;
    width: 100%;
  }
  .left-part {
    flex: 1;
    width: 100%;
    position: unset;
  }
  .content-resources {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .tab-container-card {
    width: 49%;
  }
}

@media (max-width: 500px) {
  .tab-container-card {
    width: 48.5% !important;
  }
}

@media (max-width: 400px) {
  .tab-container-card {
    width: 100% !important;
  }
}

.right-container {
  flex: 0.8;
  width: 100%;
}
