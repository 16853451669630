.resourceUpload-container {
  left: 0;
  width: 100% !important;
  margin-top: 1em;
}

.heading-resourceUpload {
  background-color: rgba(239, 68, 93, 1);
  color: white;
  padding: 5px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1.5em;
}

.upload-resources-button-group {
  width: 100% !important;
}

.file-label {
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.icon-button-resources {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.resource-upload-button {
  background-color: var(--theme-color) !important;
  color: white !important;
}

.resource-disabled-button {
  background-color: darkgray !important;
  color: black !important;
}

.category-container,
.folder-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 25% !important;
  height: max-content;
}

.row-resourceUpload {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 2px solid var(--theme-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  -webkit-border-radius: 7.5px;
  -moz-border-radius: 7.5px;
  -ms-border-radius: 7.5px;
  -o-border-radius: 7.5px;
  border-radius: 7.5px;
  padding: 20px;
  gap: 25px;
  width: 100% !important;
  height: max-content;
}

.form-resourceUpload {
  display: flex;
  width: 100% !important;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  height: max-content;
}

.resourceUpload-dropdown {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100% !important;
}

.resourceUpload-dropdown > label {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 0.9em;
}

.resourceUpload-input {
  display: flex;
  flex-direction: column;
}

.resourceUpload-input > label {
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 0.9em;
}

.resourceUpload-input-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.resourceUpload-input-new > button {
  width: 100% !important;
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: var(--theme-color);
  color: #fff;
}

.row-text {
  font-weight: 600;
}

#fetch-data {
  width: 100% !important;
  border: none;
  border-radius: 0;
  background-color: var(--theme-color);
  color: #fff;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .option-container {
    flex-wrap: wrap;
  }

  .category-container,
  .folder-container {
    width: 100% !important;
    height: max-content;
  }
  .row-resourceUpload {
    width: 100% !important;
  }
  .form-resourceUpload {
    width: 100% !important;
  }
}

@media (max-width: 966px) {
  .form-resourceUpload {
    justify-content: center;
    flex-wrap: wrap;
  }
  .resourceUpload-container {
    margin-bottom: 140px !important;
  }

  .category-container,
  .folder-container {
    width: 100% !important;
    height: max-content;
  }
  .row-resourceUpload {
    width: 100% !important;
  }
  .form-resourceUpload {
    width: 100% !important;
  }
}

@media (max-width: 400px) {
  .upload-resources-button-group {
    flex-wrap: wrap;
    width: 100%;
  }
  .upload-button-resources {
    width: 100% !important;
  }
}
