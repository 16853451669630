.situationContainer {
  margin-bottom: 8rem;
}

.mapContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start !important;
  justify-content: center !important;
  width: 100%;
  gap: 1rem;
}

.row {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
  padding-top: 10px;
}

.mapContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 1rem;
  width: 50%;
  padding: 0.5rem;
  border-radius: 5px;
}

.mapContentWorld {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  gap: 1rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
}

.visualDataTitle {
  background-color: var(--map-header);
  color: white;
  text-align: center;
  padding: 52px;
  font-size: 1.2rem;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  text-align: center;
}

.controls > button {
  padding: 0;
  height: 2rem;
  width: 2rem;
  background: #f7f7f7;
  color: #000;
  border: 0.8px solid #c5c5c5;
}

.controls > button:first-child {
  border-bottom: none;
}

.mapContainerRight {
  display: flex;
  width: max-content;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between !important;
  gap: 2.5rem;
}

.colorScaleContainer {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.colorScaleDots {
  width: 20px !important;
  height: 20px !important;
  margin-right: 8px;
}

.colorScale {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  padding: 0 0.5rem;
}

.firstColumn {
  color: var(--theme-color) !important;
  font-weight: 600;
}

/* Tooltip */

.styles-module_tooltip__mnnfp {
  border-radius: 5px;
}

.styles-module_dark__xNqje {
  background: var(--theme-color-dark);
  color: var(--rt-color-white);
}

@media (max-width: 775px) {
  .mapContainer {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .controls {
    flex-direction: row;
  }

  .controls > button:first-child {
    border-right: none !important;
    border-bottom: 0.8px solid #c5c5c5;
  }

  .mapContainerRight {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .colorScaleContainer {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100% !important;
    margin-bottom: 10rem;
  }
}

@media (max-width: 500px) {
  .filters {
    flex-wrap: wrap;
  }
}
